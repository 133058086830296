import * as React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

import Header from "../Header"
import Footer from "../Footer"
import { withTrans } from "../../i18n/withTrans"
import SectionStore from "../../store/sectionContext"

const MainContainer = tw.main`
font-display min-h-screen text-secondary-900 overflow-hidden
`

const Layout = props => {
  if (props.path === "/404.html") {
    return <>{props.children}</>
  } else {
    return (
      <SectionStore>
        <Header />
        <MainContainer>
          {props.children}
          <Footer />
        </MainContainer>
      </SectionStore>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
