module.exports = {
  tr: {
    isDefault: true,
    path: "tr",
    display_name: "Turkish",
  },
  en: {
    isDefault: false,
    path: "en",
    display_name: "English",
  },
}
