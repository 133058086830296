import { GA_COOKIE_NAME } from "./src/constants/analytics"
import { getCookie, setCookie } from "./src/helpers/cookie"
import "./src/styles/global.css"

const onClientEntry = () => {
  const isGaActive = getCookie(GA_COOKIE_NAME)

  // If cookie is empty, it will be set "true" as a default behaviour.
  if (isGaActive === "") {
    setCookie(GA_COOKIE_NAME, true, 10000)
  }

  // document.getElementById("loader-wrapper").classList.add("animate-fade-in-down")
  document.getElementById("___gatsby").style.visibility = "hidden"
  document.getElementById("loader-wrapper").style.opacity = 1
  document.getElementById("loader-wrapper").style.visibility = "visible"
}

const onPreRouteUpdate = () => {
  // document.getElementById("loader-wrapper").classList.add("animate-fade-in-down")
  document.getElementById("___gatsby").style.visibility = "hidden"
  document.getElementById("loader-wrapper").style.opacity = 1
  document.getElementById("loader-wrapper").style.visibility = "visible"
}

const onRouteUpdateDelayed = () => {
  // document.getElementById("loader-wrapper").classList.add("animate-fade-in-down")
  document.getElementById("___gatsby").style.visibility = "hidden"
  document.getElementById("loader-wrapper").style.opacity = 1
  document.getElementById("loader-wrapper").style.visibility = "visible"
}

const onRouteUpdate = () => {
  setTimeout(() => {
    document.getElementById("___gatsby").style.visibility = "visible"
    document.getElementById("___gatsby").style.opacity = 1
    document.getElementById("loader-wrapper").style.opacity = 0
    document.getElementById("loader-wrapper").style.visibility = "hidden"
  }, 100)
}
export { onClientEntry, onPreRouteUpdate, onRouteUpdateDelayed, onRouteUpdate }
