//const GA_TRACKING_ID = "G-7VM4BC94ET"
const GA_TRACKING_ID = "UA-203709183-1"
const GA_COOKIE_NAME = "wesight-ga"
const GA_COOKIE_BOX_NAME = "wesight-cookie-box-ga"
const GA_TAG_MANAGER = "GTM-PGNGK7S"
const YANDEX_METRIKA_ID = "87473196"

module.exports = {
  GA_COOKIE_NAME,
  GA_TRACKING_ID,
  GA_COOKIE_BOX_NAME,
  GA_TAG_MANAGER,
  YANDEX_METRIKA_ID
}
