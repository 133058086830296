import React, { createContext, useReducer } from "react"

// Create context.
export const SectionContext = createContext()

const initialState = {
  visiblePages: [],
  count: 0,
}

export const sectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PAGE":
      return { visiblePages: [...state.visiblePages, action.payload] }
    // case "REMOVE_PAGE":
    //   return { ...state, age: action.payload }
    default:
      throw new Error("Wrong reducer.")
  }
}

// Create store.
const SectionStore = ({ children }) => {
  const [state, dispatch] = useReducer(sectionReducer, initialState)

  return (
    <SectionContext.Provider value={{ state, dispatch }}>
      {children}
    </SectionContext.Provider>
  )
}

export default SectionStore
