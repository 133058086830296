import React from "react"
import tw from "twin.macro"
import LocalLink from "../../../Common/LocalLink"

export const NavTextLink = tw(LocalLink)`
text-sm my-2 lg:mx-2 lg:my-0 
font-semibold tracking-wide transition[all] duration-300
pb-1 border-b-2 border-transparent hover:border-blue-800 hover:text-gradient
focus:outline-none outline-none
`

function LanguageNav() {

  const isBrowser = () => typeof window !== "undefined"
  
  if(isBrowser() && window?.location?.href?.includes("blog")) {
    return (
      <>
        
      </>
    )
  }

  else {
    return (
      <>
        <NavTextLink tw="lg:ml-10" to="en" isLangSwitch>
          EN
        </NavTextLink>
        <NavTextLink to="tr" isLangSwitch>
          TR
        </NavTextLink>
      </>
    )
  }
  
}

export default LanguageNav
