import styled from "@emotion/styled"
import tw from "twin.macro"
import LocalLink from "./LocalLink";

// based on AnchorLink.
export const CustomLink = styled(LocalLink)`
  ${tw` lg:ml-6
  px-6 py-3 rounded-md text-white font-semibold 
  transition[all] duration-300 hover:shadow-lg focus:outline-none
  border-b-0`}

  background: linear-gradient(115deg, rgb(105, 147, 255) 0%, rgb(78, 109, 187) 100%);
`

export const ButtonLg = styled(LocalLink)`
  ${tw`cursor-pointer font-bold px-8 lg:px-10 py-3 rounded-5xl text-white
 transition[all] duration-300 hover:shadow-lg focus:outline-none`}

  background: linear-gradient(115deg, rgb(105, 147, 255) 0%, rgb(78, 109, 187) 100%);

  ${props => props.inline && tw`inline-flex`}
  ${props => props.animate && tw`animate-pulse`} //TODO: Gerçek pulse effekt eklenecek.

  &:hover {
    svg {
      ${tw`transform translate-x-2 transition[all] duration-300`}
    }
  }
`
