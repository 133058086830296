export const ID_HOME = "home"
export const ID_SOLUTIONS = "solutions"
export const ID_FEATURES = "features"
export const ID_HOW_IT_WORKS = "howItWorks"
export const ID_PRICING = "pricing"
export const ID_BLOG = "blog"
export const ID_REFERENCES = "references"
export const ID_CONTACT_US = "contact-us"
export const ID_FAQ = "faqs"

export const NavLinks = [
  { id: 1, idText: ID_HOME, label: "NAV_HOME", path: `#${ID_HOME}`, primaryLink: false },
  { id: 2, idText: ID_SOLUTIONS, label: "NAV_SOLUTIONS", path: `#${ID_SOLUTIONS}`, primaryLink: false },
  // { id: 3, idText: ID_FEATURES, label: "NAV_FEATURES", path: `#${ID_FEATURES}`, primaryLink: false },
  // { id: 4, idText: ID_HOW_IT_WORKS, label: "NAV_HOW_IT_WORKS", path: `#${ID_HOW_IT_WORKS}`, primaryLink: false },
  { id: 5, idText: ID_BLOG, label: "NAV_BLOG", path: `${ID_BLOG}`, primaryLink: false, isDefault: true },
  // { id: 6, idText: ID_REFERENCES, label: "NAV_REFERENCES", path: `#${ID_REFERENCES}`, primaryLink: false },
  { id: 7, idText: ID_FAQ, label: "NAV_FAQ", path: `#${ID_FAQ}`, primaryLink: false },
  // { id: 8, idText: ID_CONTACT_US, label: "NAV_CONTACTUS", path: `#${ID_CONTACT_US}`, primaryLink: true },
]
