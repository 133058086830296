import React, { useCallback, useEffect } from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"

import { NavLinks } from "../../../../constants/navLinks"
import { CustomLink } from "../../../Common/Button"
import useIntObserver from "../../../../hooks/useIntObserver"
import LocalLink from "../../../Common/LocalLink"
import LanguageNav from "../LanguageNav"

/**
 * Desktop Navigation Items
 */
const NavLinksWrapper = tw.div`inline-block`

export const NavLink = styled(LocalLink)`
  ${tw`text-base my-2 lg:mx-6 lg:my-0
font-semibold tracking-wide transition[all] duration-300
pb-1 border-b-2 border-transparent hover:border-blue-800 hover:text-gradient
`}
  @media (min-width: 1024px) and (max-width: 1282px){
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    font-size: 0.8rem;
  }
  &.active-nav {
    ${tw`text-gradient border-blue-800`}
  }
`

const MappedNavLinks = () => {
  const { t } = useTranslation()

  return (
    <>
      {NavLinks.map(link => !link.primaryLink ? (
      <NavLink
        key={link.id}
        to={link.path}
        isDefault={link.isDefault}
        gatsbyLinkProps={{ "data-navid": link.idText }}
      >
        {t(`${link.label}`)}
      </NavLink>
      ) : (
      <CustomLink key={link.id} to={link.path} isDefault={link.isDefault}>
        {t(`${link.label}`)}
      </CustomLink>
      ))}
      {/* <LoginLink href="http://app.mediapot.net">{t("NAV_LOGIN")}</LoginLink> */}
    </>
  )
}

const Nav = () => {
  const { currentEntry } = useIntObserver()

  const addActiveClass = useCallback(() => {
    if (currentEntry !== "") {
      const element = document.querySelector(`a[data-navid="${currentEntry}"`)
      const currentActiveEl = document.querySelector(`a.active-nav`)
      if (currentActiveEl) currentActiveEl.classList.remove("active-nav")
      if (element) element.classList.add("active-nav")
    }
  }, [currentEntry])

  useEffect(() => {
    addActiveClass()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEntry])

  return (
    <NavLinksWrapper>
      <MappedNavLinks />
      <LanguageNav />
    </NavLinksWrapper>
  )
}

export default Nav
