import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"

import LocalLink from "../../../Common/LocalLink"

import LogoSvg from "../../../../images/logos/wesight_logo2.svg"

export const LogoWrapper = styled(LocalLink)`
  ${tw`m-1 md:m-0 transition[all] duration-300
       flex items-start justify-start`}

  svg {
    ${tw`w-56 h-auto mr-3`}
  }
`

function Logo() {
  const { language } = useTranslation().i18n
  return (
    <LogoWrapper to="">
      <LogoSvg />
    </LogoWrapper>
  )
}

export default Logo
