import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useTranslation } from "react-i18next"
import languages from "../../i18n/languages"

export default function LocalLink({ to, isLangSwitch, isDefault, ...rest }) {
  const { i18n } = useTranslation()
  const { language } = i18n

  let path

  if (!isLangSwitch) {
    path = languages[language].isDefault ? `/${to}` : `/${language}/${to}`
  } else {
    path = languages[to].isDefault ? "/" : `/${to}/`
  }

  if(isDefault) {
    path = `/${to}`
  }
  return <AnchorLink to={path} {...rest} />
}
