import { useEffect, useState } from "react"
import {
  ID_HOME,
  ID_SOLUTIONS,
  ID_FEATURES,
  ID_HOW_IT_WORKS,
  ID_PRICING,
  ID_REFERENCES,
  ID_CONTACT_US,
} from "../constants/navLinks"

const config = {
  rootMargin: "-50px 0px -55%",
}

function useIntObserver() {
  const [currentEntry, setCurrentEntry] = useState("")

  useEffect(() => {
    const sections = document.querySelectorAll(`
    div#${ID_HOME},
    div#${ID_SOLUTIONS},
    div#${ID_FEATURES},
    div#${ID_HOW_IT_WORKS},
    div#${ID_PRICING},
    div#${ID_REFERENCES},
    div#${ID_CONTACT_US}
    `)

    let observer = new IntersectionObserver(function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setCurrentEntry(state => {
            if (entry.target.id === currentEntry) return state
            return entry.target.id
          })
        }
      })
    }, config)

    sections.forEach(section => {
      observer.observe(section)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { currentEntry }
}

export default useIntObserver
