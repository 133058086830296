import styled from "@emotion/styled"
import React from "react"
import tw from "twin.macro"
import { useScrollPosition } from "../../hooks/useScrollPosition"

import Logo from "./components/Logo"
import MobileNav from "./components/MobileNav"
import Nav from "./components/Nav"

const HeaderContainer = styled.header`
  ${tw`fixed w-full max-w-full mx-auto z-50 p-1 px-4 md:p-2 md:px-8 font-display transition["all"] duration-300`}

  backdrop-filter: saturate(180%) blur(5px);
  background-color: #ffffffcc;

  ${({ direction }) =>
    direction === "down" ? tw`transform lg:-translate-y-20 sm:translate-y-0 ` : tw`transform translate-y-0`}
`

const HeaderWrapper = tw.div`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`

const DesktopNavLinks = tw.nav`
  hidden lg:flex w-full justify-between items-center
`

const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between lg:hidden`

const Header = () => {
  const directionValue = useScrollPosition()

  return (
    <HeaderContainer direction={directionValue}>
      <HeaderWrapper>
        <DesktopNavLinks>
          <Logo />
          <Nav />
        </DesktopNavLinks>
        <MobileNavLinksContainer>
          <Logo />
          <MobileNav />
        </MobileNavLinksContainer>
      </HeaderWrapper>
    </HeaderContainer>
  )
}

export default Header
