import i18next from "i18next"

i18next.init({
  fallbackLng: "tr",
  resources: {
    "en": {
      translations: require("../locales/en/en.json"),
    },
    "tr": {
      translations: require("../locales/tr/tr.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  // debug: process.env.NODE_ENV === "development",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  // react: {
  //   wait: true,
  // },
})

i18next.languages = ["tr", "en"]

export default i18next
