import React, { Component } from "react"
import i18next from "./config"
import { I18nextProvider, withTranslation } from "react-i18next"

export function withTrans(WrappedComponent) {
  WrappedComponent = withTranslation()(WrappedComponent)

  return class extends Component {
    render() {
      const { pageContext } = this.props
      if (pageContext) {
        const currentLang = pageContext.locale
        if (currentLang && currentLang !== i18next.language) {
          i18next.changeLanguage(currentLang)
        }
      }

      return (
        <I18nextProvider i18n={i18next}>
          <WrappedComponent {...this.props} language={i18next.language} />
        </I18nextProvider>
      )
    }
  }
}
