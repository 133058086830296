import { useRef, useLayoutEffect, useState } from "react"

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export function useScrollPosition(
  effect,
  deps,
  element,
  useWindow,
  wait = 300
) {
  const position = useRef(getScrollPosition({ useWindow }))
  const throttleTimeout = useRef(null)
  const [direction, setDirection] = useState("up")

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })

    if (effect) {
      effect({
        prevPos: position.current,
        currPos,
      })
    }

    setDirection(state => {
      const result = (currPos.y < position.current.y && currPos.y < -101) ? "down" : "up"
      if (result === state) return state
      return result
    })
    position.current = currPos

    throttleTimeout.current = null
  }

  useLayoutEffect(
    () => {
      const handleScroll = () => {
        if (wait) {
          if (throttleTimeout.current === null) {
            throttleTimeout.current = setTimeout(callBack, wait)
          }
        } else {
          callBack()
        }
      }

      window.addEventListener("scroll", handleScroll)

      return () => window.removeEventListener("scroll", handleScroll)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? deps : []
  )

  return direction
}
