import React from "react"
import i18next from "../i18n/config"
import FacebookIcon from "../images/icons/facebook-icon.svg"
import TwitterIcon from "../images/icons/twitter-icon.svg"
import YoutubeIcon from "../images/icons/youtube-icon.svg"

export const huaweiCompanyName =
  "© 2021 Huawei Telekomünikasyon Dış Ticaret Ltd. Şti."

export const footerSlogan = "Customer Voice is the best choice!"

export const footerAddress = [
  { id: 1, label: "Saray Mah. Ahmet Tevfik ileri Cad." },
  { id: 2, label: "Onur Ofis Park İş Merkezi " },
  { id: 3, label: "Sit. A1Blok Apt. No.10 B/1 Ümraniye İstanbul" },
]

export const footerSocialLinks = [
  {
    id: 1,
    icon: <FacebookIcon />,
    path: "https://www.facebook.com/HuaweiTurkiye",
  },
  { id: 2, icon: <TwitterIcon />, path: "https://twitter.com/HuaweiTurkiye" },
  { id: 3, icon: <YoutubeIcon />, path: "https://www.youtube.com/@huawei" },
]

export const footerHuaweiLinks = [
  { id: 1, label: "Huawei", path: "https://www.huawei.com/tr/" },
  {
    id: 2,
    label: "FOOTER_HUAWEI_RND",
    path:
      "https://www.huawei.com/tr/corporate-information/research-development",
  },
]

export const footerOtherProductsLinks = [
  {
    id: 1,
    label: "Talent Interview",
    path: "https://www.talent-interview.com/",
  },
]

export const footerDisclaimerLinks = [
  { id: 1, label: "BLOG", path: "blog", defaultPath: true },
  // { id: 2, label: "FOOTER_CONTACT", path: "#contact-us", defaultPath: false },
  {
    id: 3,
    label: "FOOTER_TERMS_OF_USE",
    path: "terms-of-use",
    defaultPath: false,
  },
  {
    id: 4,
    label: "FOOTER_PRIVACY_POLICY",
    path: "privacy-policy",
    defaultPath: false,
  },
  { id: 5, label: "FOOTER_COOKIES", path: "cookies", defaultPath: false },
]
