import React from "react"
import { motion } from "framer-motion"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"

import { NavLinks } from "../../../../constants/navLinks"
import useAnimatedNavToggler from "../../../../hooks/useAnimatedNavToggler"
import MenuIcon from "../../../../images/icons/menu.svg"
import CloseIcon from "../../../../images/icons/x.svg"
import LocalLink from "../../../Common/LocalLink"
import LanguageNav from "../LanguageNav"

/**
 * Mobile Navigation Items
 */

export const NavToggle = styled.button`
  ${tw`lg:hidden z-20 focus:outline-none transition["all"] duration-300`}

  .fixed-icon {
    ${tw`fixed w-6 h-6 text-white`}
    right: 3rem;
    top: 3rem;
  }
`
export const MobileNavLinks = motion(styled.div`
  ${tw`h-screen lg:hidden z-10 fixed top-0 inset-x-0 p-8 border-4 
  text-center text-white bg-black bg-opacity-75`}

  & > .mobile-container {
    ${tw`h-full flex flex-col flex-1 justify-center items-center`}
    z-index: 10001; // Over ScrollToTop component. (Its z-index is 10000)
  }
`)

const NavLink = tw(LocalLink)`
text-2xl my-4 lg:mx-6 lg:my-0
font-semibold tracking-wide transition[all] duration-300
pb-1 border-transparent hover:border-primary-500
`

const PrimaryLink = styled(NavLink)`
  ${tw`lg:mx-0
  px-6 py-3 rounded-md text-gray-100
  border-b-0 `}

  width: 20rem;
  background: linear-gradient(
    115deg,
    rgb(105, 147, 255) 0%,
    rgb(78, 109, 187) 100%
  );
`

const MappedNavLinks = ({ handleClick }) => {
  const { t } = useTranslation()
  return (
    <>
      {NavLinks.map(link =>
        !link.primaryLink ? (
          <NavLink key={link.id} to={link.path} onClick={handleClick}>
            {t(`${link.label}`)}
          </NavLink>
        ) : (
          <PrimaryLink key={link.id} to={link.path} onClick={handleClick}>
            {t(`${link.label}`)}
          </PrimaryLink>
        )
      )}
      {/* Login link */}
      {/* <LoginLink href="http://www.wesight.io" onClick={handleClick}>
        {t("NAV_LOGIN")}
      </LoginLink> */}
    </>
  )
}

const MobileNav = () => {
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler()
  return (
    <>
      <MobileNavLinks
        initial={{ x: "150%", display: "none" }}
        animate={animation}
        css={tw`md:hidden`}
      >
        <div className="mobile-container">
          <MappedNavLinks handleClick={toggleNavbar} />
          <div tw="inline-flex justify-center space-x-10 mt-5">
            <LanguageNav />
          </div>
        </div>
      </MobileNavLinks>
      <NavToggle onClick={toggleNavbar}>
        {showNavLinks ? (
          <CloseIcon className="fixed-icon" />
        ) : (
          <MenuIcon tw="w-6 h-6" />
        )}
      </NavToggle>
    </>
  )
}

export default MobileNav
